import './App.css';

import { useState } from "react";

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import DownloadFunctions from './DownloadFunctions';

import RegisterTrialLicenseDialog from './RegisterTrialLicenseDialog'

function MapPageDownload() {

    const [sLicenseKey, setLicenseKey] = useState('');

    const [sShowProductsPage, setShowProductsPage] = useState(false);
    const [sDisableNextButton, setDisableNextButton] = useState(false);
    const [sStartDownloadError, setStartDownloadError] = useState('');

    const [sClientName, setClientName] = useState('');
    const [sFileNames, setFileNames] = useState([]);
    const [sOtherDownloadError, setOtherDownloadError] = useState('');

    const [sShowEndUserLicenseAgreementDialog, setShowEndUserLicenseAgreementDialog] = useState(false);

    const [sIsRegisterTrialLicenseDialogOpen, setIsRegisterTrialLicenseDialogOpen] = useState(false);
    const [sTrialLicenseRegistrationStatus, setTrialLicenseRegistrationStatus] = useState('');

    const [sProductGuideSamples, setProductGuideSamples] = useState([]);
    const [sProductGuideSamplesError, setProductGuideSamplesError] = useState('');

    const [sIsGuideSample, setIsGuideSample] = useState(true);
    const [sCurrentProductEdition, setCurrentProductEdition] = useState(-1);
    const [sCurrentFileName, setCurrentFileName] = useState('');

    function onLicenseKeyChange(e) {
        setLicenseKey(e.target.value);
    }

    function onNextButtonClick() {
        if (sLicenseKey.length < 10) {
            setStartDownloadError("Invalid license key.");
            return;
        }

        setDisableNextButton(true);
        setStartDownloadError('');
        DownloadFunctions.startDownload(sLicenseKey);
    }

    function showProductsPage(clientName, fileNames) {
        setClientName(clientName);
        setFileNames(fileNames);
        setShowProductsPage(true);
    }

    function onDownloadButtonClick(e) {
        setIsGuideSample(false);
        DownloadFunctions.FileNameToDownload = e.target.value;
        setShowEndUserLicenseAgreementDialog(true);
    }

    function onDownloadGuideSampleButtonClick(e) {
        setIsGuideSample(true);
        const items = e.target.value.split(',');
        setCurrentProductEdition(parseInt(items[0]));
        setCurrentFileName(items[1]);
        setShowEndUserLicenseAgreementDialog(true);
    }

    function agreeLicenseAgreementButtonClick() {
        setShowEndUserLicenseAgreementDialog(false);
        if (sIsGuideSample) {
            setProductGuideSamplesError('');
            DownloadFunctions.downloadGuideSample(sCurrentProductEdition, sCurrentFileName);                
        }
        else{
            setOtherDownloadError('');
            DownloadFunctions.getLicenseAgreementToken(sLicenseKey);
        }
    }

    function notAgreeLicenseAgreementButtonClick() {
        setShowEndUserLicenseAgreementDialog(false);
        setOtherDownloadError('');
    }

    function onRegisterButtonClick() {
        setTrialLicenseRegistrationStatus("");
        setIsRegisterTrialLicenseDialogOpen(true);
    }

    function closeTrialLicenseDialog() {
        setIsRegisterTrialLicenseDialogOpen(false);
    }

    function closeTrialLicenseDialogAndDisplayStatus(status) {
        setIsRegisterTrialLicenseDialogOpen(false);
        setTrialLicenseRegistrationStatus(status);
    }

    function getProductGuideSamples() {
        return sProductGuideSamples;
    }

    DownloadFunctions.readEndUserLicenseAgreement();

    DownloadFunctions.showProductsPage = showProductsPage;
    DownloadFunctions.setStartDownloadError = setStartDownloadError;
    DownloadFunctions.setOtherDownloadError = setOtherDownloadError;

    DownloadFunctions.getProductGuideSamples = getProductGuideSamples;
    DownloadFunctions.setProductGuideSamples = setProductGuideSamples;

    DownloadFunctions.setProductGuideSamplesError = setProductGuideSamplesError;

    function renderLicenseInputPage() {
        return (
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <th className="Features-table-header" colspan={"2"}>
                                Please enter your commercial license key or trial license key, then click next.
                            </th>
                        </tr>
                        <tr>
                            <td className="Features-table-body" width={"80%"}>
                                <TextField multiline fullWidth id="licenseKeyTextField" label="License Key" value={sLicenseKey} onChange={onLicenseKeyChange} />
                            </td>
                            <td width={"20%"}>
                                <Button variant="contained" disabled={sDisableNextButton} onClick={onNextButtonClick} >Next</Button>
                            </td>
                        </tr>
                        <tr>
                            <td width={"80%"} align={"left"} colSpan={"2"}>
                                <p style={{ color: "red", fontSize: "18px" }}>{sStartDownloadError}</p>
                            </td>
                            <td width={"20%"}>
                            </td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
        );
    }

    function renderProductsPage() {
        return (
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <th className="Features-table-header" colspan={"4"}>
                                Welcome, {sClientName}
                            </th>
                        </tr>
                        <tr>
                            <td align={"left"} colSpan={"4"}>
                                <h3 style={{ color: "red", fontSize: "18px" }}>Please do not share these assemblies.</h3>
                                <p style={{ color: "blue", fontSize: "18px" }}>
                                    All our previous releases which we published have been hacked and stolen.
                                    Therefore, starting from Version 9, we only published trial editions of our products
                                    on our website, which constain very limited functionalities. We hope to protect our
                                    products from the hackers in this way.
                                </p>
                                <p style={{ color: "blue", fontSize: "18px" }}>Thanks for your help to keep us staying in business.</p>
                            </td>
                        </tr>
                        {
                            sFileNames.map((fileName) => {
                                return (
                                    <tr>
                                        <td width={"20%"} />
                                        <td width={"40%"} align={"left"}>
                                            <p style={{ fontSize: "18px" }}>{fileName}</p>
                                        </td>
                                        <td width={"20%"}>
                                            <Button variant="contained" value={fileName} onClick={onDownloadButtonClick} >Download</Button>
                                        </td>
                                        <td width={"20%"} />
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td align={"left"} colSpan={"4"}>
                                <p style={{ color: "red", fontSize: "18px" }}>{sOtherDownloadError}</p>
                            </td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
        );
    }

    function renderEndUserLicenseAgreementLine(licenseAgreementLine) {
        return (<p>{licenseAgreementLine}</p>);
    }

    function renderEndUserLicenseAgreementTexts()
    {
        if (DownloadFunctions.EndUserLicenseAgreementTexts !== null && DownloadFunctions.EndUserLicenseAgreementTexts.length > 0) {
            return (DownloadFunctions.EndUserLicenseAgreementTexts.map(renderEndUserLicenseAgreementLine));
        }
    }

    function renderEndUserLicenseAgreementDialog() {
        return (
            <Dialog open={sShowEndUserLicenseAgreementDialog} fullWidth maxWidth="sm">
                <DialogTitle>End User License Agreement</DialogTitle>
                <DialogContent dividers={true} >
                <Card>
                    <CardContent  >
                        <table width={"100%"} cellpadding={"10"}>
                            <tr>
                                <td>
                                    <p>GDS DESIGN & DEVELOPMENT LTD.</p>
                                    <p>Copyright (C) 2012-{(new Date()).getFullYear()} GDS Design & Development Ltd.</p>
                                    {renderEndUserLicenseAgreementTexts()}
                                    <p>Copyright (C) 2012-{(new Date()).getFullYear()} GDS Design & Development Ltd.</p>
                                    <p>All trademarks and registered trademarks are property of their respective owners</p>
                                </td>
                            </tr>
                        </table>
                    </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={agreeLicenseAgreementButtonClick} >I Agree</Button>
                    <Button variant="contained" onClick={notAgreeLicenseAgreementButtonClick} >I Don't Agree</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function renderTrialLicenseRegistration() {
        return (
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <th className="Features-table-header" colspan={"3"}>
                                Or you can register for a trial license key.
                            </th>
                            <th>
                                <Button variant="contained" onClick={onRegisterButtonClick} >Register</Button>
                            </th>
                        </tr>
                        <tr>
                            <td width={"100%"} colSpan="2" align={"left"}>
                                <p style={{ color: "red" }}>{sTrialLicenseRegistrationStatus}</p>
                            </td>
                        </tr>

                    </table>
                </CardContent>
            </Card>
        );
    }

    function renderProductGuideSamplesDownload() {
        if (sProductGuideSamples.length <= 0) {
            DownloadFunctions.getProductGuideSamplesCollection();
        } 
        return (
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <td align={"left"} colSpan={"4"}>
                                <p style={{ color: "blue", fontSize: "18px" }}>
                                    GDS Google Maps Trial Editions are published on https://www.nuget.org/.
                                </p>
                                <p style={{ color: "blue", fontSize: "18px" }}>
                                    Please download and follow the programming guides on how to install and use GDS Google Maps Trial Editions.
                                </p>
                            </td>
                        </tr>
                        {
                            sProductGuideSamples.map((guideSamples) => {
                                return (
                                    <tr>
                                        <table width={"100%"} cellpadding={"10"}>
                                            <tr>
                                                <td align={"left"} colSpan={"4"}>
                                                    <p style={{ color: "blue", fontSize: "18px" }}>
                                                    {DownloadFunctions.getProductName(guideSamples.productEdition)}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={"20%"} />
                                                <td width={"40%"} align={"left"}>
                                                    <p style={{ fontSize: "18px" }}>{guideSamples.guide}</p>
                                                </td>
                                                <td width={"20%"}>
                                                    <Button variant="contained" value={guideSamples.productEdition + ',' + guideSamples.guide}
                                                     onClick={onDownloadGuideSampleButtonClick} >Download</Button>
                                                </td>
                                                <td width={"20%"} />
                                            </tr>
                                            <tr>
                                                <td width={"20%"} />
                                                <td width={"40%"} align={"left"}>
                                                    <p style={{ fontSize: "18px" }}>{guideSamples.dotNetCoreSamples}</p>
                                                </td>
                                                <td width={"20%"}>
                                                    <Button variant="contained" value={guideSamples.productEdition + ',' + guideSamples.dotNetCoreSamples}
                                                     onClick={onDownloadGuideSampleButtonClick} >Download</Button>
                                                </td>
                                                <td width={"20%"} />
                                            </tr>
                                            <tr>
                                                <td width={"20%"} />
                                                <td width={"40%"} align={"left"}>
                                                    <p style={{ fontSize: "18px" }}>{guideSamples.dotNetSamples}</p>
                                                </td>
                                                <td width={"20%"}>
                                                    <Button variant="contained" value={guideSamples.productEdition + ',' + guideSamples.dotNetSamples}
                                                     onClick={onDownloadGuideSampleButtonClick} >Download</Button>
                                                </td>
                                                <td width={"20%"} />
                                            </tr>
                                        </table>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td align={"left"} colSpan={"4"}>
                                <p style={{ color: "red", fontSize: "18px" }}>{sProductGuideSamplesError}</p>
                            </td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
        );
    }


    return (
        <div>
            {sShowProductsPage ? renderProductsPage() : renderLicenseInputPage()}
            {renderEndUserLicenseAgreementDialog()}
            <br />
            { renderProductGuideSamplesDownload() }
            <br />
            <RegisterTrialLicenseDialog open={sIsRegisterTrialLicenseDialogOpen}
                                        closeDialog={closeTrialLicenseDialog}
                                        closeDialogAndDisplayStatus={closeTrialLicenseDialogAndDisplayStatus} />
        </div>
    );
}

export default MapPageDownload;
